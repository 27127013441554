<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <h1 class="heading-page-h1">{{ $translate('incoming-dispatch') }}</h1>
                </div>
                <div class="content__header-right">
                    <router-link type="button" class="btn btn-primary btn-standart" to="/create-dispatch">
                        <inline-svg :src="require(`@/assets/img/Plus-Add.svg`)" />
                        <span>{{ $translate('create-a-dispatch') }}</span>
                    </router-link>
                </div>
            </div>
            <TableAdvanced
                v-model="sortCol"
                :rows="filteredRows"
                :columns="columns"
                :isCheckedAll="isCheckedAll"
                :checkedInputs="checkedInputs"
                @toggleAllCheckedInputs="toggleAllCheckedInputs"
                
            >
                <template v-slot:filters>
                    <form action="#" class="filter-options__search filter-options__search--md">
                        <input type="search" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
                    </form>

                </template>
                <template v-slot:row="slotData">
                    <td>
                        <label class="module__check">
                            <input type="checkbox" name="privacy_policy" />
                            <span class="check"></span>
                            <span class="text">{{slotData.r.dispatchID}}</span>
                        </label>
                    </td>
                    <td>
                        <span class="status incoming">
                            <span class="status-circle"></span>
                            <span class="status-info">{{ $translate('incoming') }}</span>
                        </span>
                    </td>
                    <td>
                        <span class="companies-list">
                            <span class="level">{{slotData.r.mainCompany}}</span>
                            <span class="level">{{companyData.company_name}}</span>

                        </span>
                    </td>
                    <td class="position-stiky">
                        <div class="table-options-drop dropdown-wrapper">
                            <button
                                type="button" class="btn btn-sm btn-transp dropdown-btn" 
                                :class="{'active':menuOpen.open === slotData.r.dispatchID }"
                                @click="openOptions($event, slotData.r.dispatchID)"
                            >
                                <inline-svg :src="require('@/assets/img/dots-menu.svg')" class="pointer-events-none" />
                            </button>
                            <div 
                                class="categories__dropdown dropdown-content" 
                                :class="{'active':menuOpen.open === slotData.r.dispatchID }"
                                style="position: fixed;"
                                :style="{ left: menuOpen.left, top: menuOpen.top, bottom: 'auto' }"
                            >
                                <ul>
                                    <li>
                                        <router-link :to="`/dispatch/${slotData.r.id}`">{{ $translate('view-the-dispatch') }}</router-link>
                                    </li>
                                    <li @click="receiveDispatch(slotData.r.id)">
                                        <span>{{ $translate('receive-the-dispatch') }}</span>
                                    </li>
                                    <li @click="returnDispatch(slotData.r.id)">
                                        <span>{{ $translate('return-the-dispatch') }}</span>
                                    </li>
                                    <li class="list-line"></li>
                                    <li>
                                        <button type="button" class="btn red-color" data-target="delete-item-2" @click="confirmDelete(slotData.r.id,'Delete Dispatch?')">
                                            {{ $translate('delete-the-dispatch') }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </template>
            </TableAdvanced>
            <TableMessages
                :rows="rows"
                :filters="rowsFilters"
                :title="$translate('no-incoming-dispatches-yet!')"               
                :searchTitle="$translate('no-dispatches-corresponding-to-the-criteria')"
            ></TableMessages>
            <!-- before translate
                title="No Incoming Dispatches yet!"               
                searchTitle="No Dispatches Corresponding To The Criteria"
              -->
        </main>
    </div>
</template>

<script>
import Base from "@/components/base";
import { mixTables, mixDeleteItem } from "@/mixins";
import { mixDispatches } from "@/mixins/dispatch";
export default {
    name: "IncomingDispatch",
    components: {
        ...Base,
    },
    
    mixins:[mixTables, mixDeleteItem, mixDispatches],

    data() {
        return {
            source:"dispatches",

            columns: [
                { name: "Dispatch ID", filter: "dispatchID" },
                { name: "Status", filter: "localStatus" },
                { name: "Companies"},
                { name: "",},
            ],
            calculatedFields:[
            {
                filter: 'mainCompany',
				path: [],
				calcFunction: (dispatch)=>{          
                    
                    return dispatch.owner.company.company_name
                },
            },
            // {
                // filter: 'myCompany',
				// path: [],
				// calcFunction: (bathces)=>{          
                //     return dispatch.companiesStatus.find(c=>c.id==this.companyData.id)
                // },
            // }
            ],
            menuOpen: {
                left: '-500px',
                top: 0,
                open: null,
            } 
        };
    },
    computed:{
        dispatches(){
            return this.$store.state.dispatches||[]
        },
        companyData(){
            return this.$store.state.companyData||{}
        },
        sourceData(){
            return this.dispatches.filter(dispatch=>{
                let companyStatus=dispatch.companiesStatus.find(c=>{
                    return c.id==this.companyData.id
                })
                console.log(companyStatus);
                return companyStatus.status=="incoming"
            })
        },
    },
    methods: {
       openOptions(ev, id) {
            if(this.menuOpen.open === id){
                this.menuOpen.open = null;
                this.menuOpen = {
                    left: '-500px',
                    top: 0,
                    open: null,
                }
            } else {
                this.menuOpen.open = id;
                setTimeout(() => {
                    let targetClientRect = ev.target.getBoundingClientRect();

                    this.menuOpen.left = (targetClientRect.x - ev.target.nextElementSibling.offsetWidth + 22) + 'px';

                    if((targetClientRect.top + targetClientRect.height + ev.target.nextElementSibling.offsetHeight+70) > document.querySelector('#app').offsetHeight){
                        this.menuOpen.top = ((targetClientRect.y - ev.target.nextElementSibling.offsetHeight) - 10) + 'px';
                    } else {
                        this.menuOpen.top = (targetClientRect.y + 15) + 'px';
                    }
                }, 10);
            }
        },
        outsideMenuClick(e){
            if(this.menuOpen.open&&!e.target.closest('.table-options-drop.dropdown-wrapper')){
                this.menuOpen.open = null;
            }
        },
        async receiveDispatch(dispatchID) {
            const options = { title: "Receive Dispatch?", size: "sm", okLabel: "Receive", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await this.changeCompanyStatus(dispatchID, this.companyData.id, "completed");
                    await this.changeCompanyStatus(dispatchID, this.companyData.id, "receive");
                }
            });
        },
        async returnDispatch(dispatchID) {
            const options = { title: "Return Dispatch?", size: "sm", okLabel: "Return", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await this.changeCompanyStatus(dispatchID, this.companyData.id, "returned");
                }
            });
        },
    },
    async created() {
        let self = this;
        window.addEventListener('click', self.outsideMenuClick);
    },
    beforeDestroy(){
        let self = this;
        window.removeEventListener('click', self.outsideMenuClick);
    }
};
</script>
